<ion-header [translucent]="true">
  <ion-toolbar class="transparent">
    <ion-buttons slot="secondary">
      <ion-button (click)="dismiss()" color="light">
        <ion-text class="text-white">Close</ion-text>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="primary">
      <ion-button
        *ngIf="documentType === 'Document'"
        [disabled]="!documentForm.form.valid || !canSave || (s3.progress$ | async) > 0"
        class="submitDocument text-white"
        (click)="startUpload()"
      >Upload {{documentType}}</ion-button>

      <ion-button
        *ngIf="documentType === 'Image'"
        [disabled]="!canSave || (s3.progress$ | async) > 0"
        class="submitDocument text-white"
        (click)="startUpload()"
      >Upload {{documentType}}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="blue-purple-gradient montserrat" [fullscreen]="true">
  <ion-grid>
    <ion-row>
      <ion-col size="12" class="ion-padding">

        <form #documentForm="ngForm" name="documentForm">
          <ion-grid>
            <ion-row *ngIf="imageSrc" class="ion-justify-content-center">
              <ion-card class="bg-white" style="display: inline-block;">
                <img
                  *ngIf="fileType !== '.pdf'"
                  alt="cropped photo"
                  style="height: auto; width: auto; max-height: 500px;"
                  [src]="sanitization.bypassSecurityTrustResourceUrl(imageSrc)" />
                <iframe
                  *ngIf="fileType === '.pdf'"
                  [src]="sanitization.bypassSecurityTrustResourceUrl(imageSrc)"
                  height="500px"
                  width="100%"
                ></iframe>
                <ion-grid class="p-0">
                  <ion-row class="p-0 flex ion-justify-content-between ion-align-items-center">
                    <ion-col size="8" class="p-0">
                      <ion-card-header>
                        <ion-card-subtitle>New {{documentType}}</ion-card-subtitle>
                        <ion-card-title>{{newDocName || 'Enter Name Below'}}</ion-card-title>
                      </ion-card-header>
                    </ion-col>
                    <ion-col size="4" class="p-0 text-right" *ngIf="visibleToClient" >
                      <ion-badge color="warning" style="margin-right: 20px;">Visible To Client</ion-badge>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-card class="bg-white">
                  <ion-card-content>
                    <ion-card-title class="flex justify-content-space-between">
                      New {{documentType}}
                    </ion-card-title>
                    <ion-grid class="px-0">
                      <ion-row class="px-0">
                        <ion-col class="px-0">
                          <ion-list>
                            <ion-item *ngIf="isWeb">
                              <ion-label position="stacked">
                                <small>Only accepts {{supportedFileTypes.join(', ')}}</small>
                              </ion-label>
                              <ion-input
                                (change)="webFileSelected($event)"
                                type="file"
                                *ngIf="isWeb"
                                [(ngModel)]="file"
                                name="file"
                                [accept]="'image/*,' + supportedFileTypes.join(',')"
                              ></ion-input>
                            </ion-item>

                            <ng-container *ngIf="editableName">
                              <ion-item>
                                <ion-label position="floating">
                                  {{documentType}} Name (no symbols or punctuation)
                                </ion-label>
                                <ion-input
                                  [(ngModel)]="newDocName"
                                  required
                                  name="new_doc_name"
                                  #doc_name="ngModel"
                                ></ion-input>
                              </ion-item>
                              <small
                                class="text-danger ion-padding-start"
                                *ngIf="(documentForm.dirty || imageSrc) && doc_name.invalid">
                                {{documentType}} name is required
                              </small>
                            </ng-container>

                            <div class="pl-4 pb-2">
                              <ion-button
                                *ngIf="newDocName"
                                size="small"
                                color="danger"
                                (click)="newDocName = ''">
                                Clear
                              </ion-button>

                              <ion-button
                                size="small"
                                color="light"
                                *ngFor="let documentName of documentNameShortcuts"
                                (click)="insertDocumentName(documentName)">
                                {{documentName}}
                              </ion-button>
                            </div>


                            <ion-item *ngIf="documentType === 'Document'">
                              <ion-label position="stacked">Visible To Client</ion-label>
                              <ion-toggle
                                [(ngModel)]="visibleToClient"
                                name="visibleToClient"
                                class="mb-2"
                              ></ion-toggle>
                            </ion-item>
                          </ion-list>
                        </ion-col>
                      </ion-row>
                      <ion-row class="ion-justify-content-center">
                        <ion-button
                          *ngIf="documentType === 'Document'"
                          [disabled]="!documentForm.form.valid || !canSave || (s3.progress$ | async) > 0"
                          color="primary"
                          class="submitDocument"
                          (click)="startUpload()"
                        >Upload {{documentType}}</ion-button>

                        <ion-button
                          *ngIf="documentType === 'Image'"
                          [disabled]="!canSave || (s3.progress$ | async) > 0"
                          color="primary"
                          class="submitDocument"
                          (click)="startUpload()"
                        >Upload {{documentType}}</ion-button>
                      </ion-row>

                      <ion-row *ngIf="(s3.progress | async) > 0">
                        <ion-col>
                          <ion-progress-bar [value]="s3.progress | async"></ion-progress-bar>
                        </ion-col>
                      </ion-row>
                    </ion-grid>

                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-fab slot="fixed" vertical="bottom" horizontal="end" class="awaken-fab" *ngIf="!isWeb">
  <ion-fab-button color="success" (click)="selectImage()" *ngIf="!file">
    <ion-icon name="camera"></ion-icon>
  </ion-fab-button>
</ion-fab>
