import {IConsultation} from './consultation';
import {Address, IAddress} from './address_model';
import {IHealthProfile} from './health_profile';
import {IUser} from './user';
import {IReferral} from './referral';
import {IPurchase, IRefund} from './finance';
import {IComment} from './comment';
import {ICheckIn} from './check_in';
import {INotification} from './notification';
import {IProgram} from './program';
import {Goal} from './goal';
import * as moment from 'moment';
import {IReminder} from './reminder';

export interface IClientPhotos {
  before_front?: string;
  after_front?: string;
  before_side?: string;
  after_side?: string;
  image_approved?: boolean;
}

export interface IBasicClient extends IClientPhotos {
  id?: number;
  name?: string;
  phone?: string;
  email?: string;
  location_name?: string;
  beta_tester?: boolean;
  start_date?: string;
  dob?: string;
  mindbody_id?: number;
  invited_to_awaken_app_at?: string;
  awaken_app_sign_in_at?: string;
  program_status?: string;
  program_id?: number;
  consultant?: string;
  recent_daily_checkins?: number;
  eligble_for_awaken_app?: boolean;
  recommended_weeks?: number;
  health_profiles?: IHealthProfile[];
  purchased_weeks?: number;
  has_meals?: boolean;
  used_weeks?: number;
  num_products?: number;
  body_fat_target?: number;
  repo_comments?: {
    id: number;
    description: string;
    created_at: string;
    employee: string;
  }[];
  finance_comments?: IComment[];
  body_fat_target2?: number;
  measurements?: {
    id: number;
    body_fat: number;
    current_weight: number;
    visceral_fat: number;
    date: string;
  }[];
  visceral_fat_target?: number;
  balance?: number;
  visceral_fat_target2?: number;
  current_body_fat?: number;
  current_visceral_fat?: number;
  consultation_id?: number;
  current_weight?: number;
  target_weight?: number;
  target_weight2?: number;
  unique_mb?: number;
  coach_care_first_logged_in_at?: string;
  website_sign_in_at?: string;
  user?: any;
  consent_signed?: boolean;
  weightloss?: number;
  has_food_orders?: boolean;
  referrals?: IReferral[];
  height?: string;
  gender?: string;
  referred_by?: string;
  total_visits?: number;
  medications?: string;
  health_notes?: string;
  doctor_sent?: boolean;
  doctor_received?: boolean;
  care?: boolean;
  coach_care_id?: number;
  program_type?: string;
  address?: IAddress;
  programs?: IProgram[];
  goals?: Goal[];
  refunds?: IRefund[];
  coach_care_account_created_at?: string;
  coach_care_scale_requested_at?: string;
  has_coach_care_visits?: boolean;
  notification?: INotification;
  repositioning_email_opt_in?: boolean;
  default_payment_amount?: number;
  reminders?: IReminder[];
  future_appointment_datetime?: string;

  // remove
  location_id?: any;
  user_id?: any;
}

export interface IClient extends IBasicClient {
  athlete_mode?: boolean;
  gender?: string;
  height?: string;

  occupation?: string;
  user_id?: number;
  location_id?: number;
  unique_mb?: number;
  care?: boolean;
  email_opt_in?: boolean;

  before_front?: string;
  before_side?: string;
  after_front?: string;
  after_side?: string;
  doctor_sent?: boolean;
  doctor_received?: boolean;
  medications_health_profile?: string;
  pending_coachcare_check_in_info?: any;
  has_coach_care_visits?: boolean;
  has_food_orders?: boolean;
  coach_care_id?: number;

  image_approved?: boolean;
  user?: IUser;
  referrals?: IReferral[];
  consultation?: IConsultation;
  purchases?: IPurchase[];
  comments?: IComment[];
  check_ins?: ICheckIn[];
  reminders?: IReminder[];

  isManager?: () => boolean;
}

export class Client implements IClient {
  public id?: number;
  public name?: string;
  public phone?: string;
  public athlete_mode?: boolean;
  public target_weight?: number;
  public email_opt_in?: boolean;
  public body_fat_target?: number;
  public visceral_fat_target?: number;
  public occupation?: string;
  public referred_by?: string;
  public start_date?: string;
  public used_weeks?: number;
  public medications?: string;
  public health_notes?: string;
  public user_id?: number;
  public dob?: string;
  public location_id?: number;
  public mindbody_id?: number;
  public unique_mb?: number;
  public care?: boolean;
  public recommended_weeks?: number;
  public user?: IUser;
  public target_weight2?: number;
  public program_id?: number;
  public before_front?: string;
  public before_side?: string;
  public after_front?: string;
  public total_visits?: number;
  public default_payment_amount?: number;
  public email?: string;
  public goals?: Goal[];
  public after_side?: string;
  public program_status?: string;
  public doctor_sent?: boolean;
  public medications_health_profile?: string;
  public doctor_received?: boolean;
  public body_fat_target2?: number;
  public weightloss?: number;
  public balance?: number;
  public visceral_fat_target2?: number;
  public image_approved?: boolean;
  public location_name?: string;
  public coach_care_account_created_at?: string;
  public coach_care_scale_requested_at?: string;
  public has_coach_care_visits?: boolean;
  public address?: Address;
  public programs?: IProgram[];
  public health_profiles?: IHealthProfile[];
  public website_sign_in_at?: string;
  public referrals?: IReferral[];
  public coach_care_id?: number;
  public notification?: INotification;
  public consultation?: IConsultation;
  public eligble_for_awaken_app?: boolean;
  public purchases?: IPurchase[];
  public comments?: IComment[];
  public reminders?: IReminder[];
  public check_ins?: ICheckIn[];
  public gender?: string;
  public repositioning_email_opt_in?: boolean;
  public future_appointment_datetime?: string;
  public invited_to_awaken_app_at?: string;

  constructor(data: Partial<IClient>) {
    Object.assign(this, data);
  }

  ordersOnline() {
    return (
      this.location_name === "Outreach" || this.location_name === "At Home"
    );
  }

  isWorldwide() {
    return this.location_name === "At Home";
  }

  /**
   * Fetches the client targets for clients that are in person.  The scale is
   * different from At Home and thus has different targets.
   * @param field: The field whose target we are looking for
   * @param age: The client's age
   * @param gender: The client's gender
   */
  fetchInLocationTarget(field, age, gender) {
    switch (field) {
      // Higher
      case "visceral_fat_target":
        switch (gender) {
          case "male":
            if (age < 40) {
              return 8;
            } else if (age >= 40 && age < 50) {
              return 10;
            } else if (age >= 50 && age < 60) {
              return 12;
            } else if (age >= 60) {
              return 13;
            }
            break;
          case "female":
            if (age < 30) {
              return 3;
            } else if (age >= 30 && age < 40) {
              return 5;
            } else if (age >= 40 && age < 50) {
              return 7;
            } else if (age >= 50 && age < 60) {
              return 7;
            } else if (age >= 60) {
              return 9;
            }
            break;
        }
        break;
      // Lower
      case "visceral_fat_target2":
        switch (gender) {
          case "male":
            if (age < 40) {
              return 6;
            } else if (age >= 40 && age < 50) {
              return 8;
            } else if (age >= 50 && age < 60) {
              return 10;
            } else if (age >= 60) {
              return 12;
            }
            break;
          case "female":
            if (age < 30) {
              return 1;
            } else if (age >= 30 && age < 40) {
              return 3;
            } else if (age >= 40 && age < 50) {
              return 3;
            } else if (age >= 50 && age < 60) {
              return 5;
            } else if (age >= 60) {
              return 5;
            }
            break;
        }
        break;
      // Higher
      case "body_fat_target":
        switch (gender) {
          case "male":
            if (age < 40) {
              return 20;
            } else if (age >= 40 && age < 60) {
              return 22;
            } else if (age >= 60) {
              return 24;
            }
            break;
          case "female":
            if (age < 30) {
              return 28;
            } else if (age >= 30 && age < 40) {
              return 29;
            } else if (age >= 40 && age < 50) {
              return 32;
            } else if (age >= 50 && age < 60) {
              return 32;
            } else if (age >= 60) {
              return 34;
            }
            break;
        }
        break;
      // Lower
      case "body_fat_target2":
        switch (gender) {
          case "male":
            if (age < 30) {
              return 16;
            } else if (age >= 30 && age < 40) {
              return 18;
            } else if (age >= 40 && age < 60) {
              return 20;
            } else if (age >= 60) {
              return 22;
            }
            break;
          case "female":
            if (age < 30) {
              return 20;
            } else if (age >= 30 && age < 40) {
              return 25;
            } else if (age >= 40) {
              return 28;
            }
            break;
        }
        break;
    }
  }

  /**
   * Fetches the client targets for clients that are in person.  The scale is
   * different from At Home and thus has different targets.
   * @param field: The field whose target we are looking for
   * @param age: The client's age
   * @param gender: The client's gender
   */
  fetchAtHomeTarget(field, age, gender) {
    switch (field) {
      // Higher
      case "visceral_fat_target":
        switch (gender) {
          case "male":
            if (age < 40) {
              return 8;
            } else if (age >= 40 && age < 50) {
              return 10;
            } else if (age >= 50 && age < 60) {
              return 12;
            } else if (age >= 60) {
              return 13;
            }
            break;
          case "female":
            if (age < 30) {
              return 3;
            } else if (age >= 30 && age < 40) {
              return 5;
            } else if (age >= 40 && age < 50) {
              return 7;
            } else if (age >= 50 && age < 60) {
              return 7;
            } else if (age >= 60) {
              return 9;
            }
            break;
        }
        break;
      // Lower
      case "visceral_fat_target2":
        switch (gender) {
          case "male":
            if (age < 40) {
              return 6;
            } else if (age >= 40 && age < 50) {
              return 8;
            } else if (age >= 50 && age < 60) {
              return 10;
            } else if (age >= 60) {
              return 12;
            }
            break;
          case "female":
            if (age < 30) {
              return 1;
            } else if (age >= 30 && age < 40) {
              return 3;
            } else if (age >= 40 && age < 50) {
              return 3;
            } else if (age >= 50 && age < 60) {
              return 5;
            } else if (age >= 60) {
              return 5;
            }
            break;
        }
        break;
      // Higher
      case "body_fat_target":
        switch (gender) {
          case "male":
            if (age < 40) {
              return 24;
            } else if (age >= 40 && age < 60) {
              return 26;
            } else if (age >= 60) {
              return 28;
            }
            break;
          case "female":
            if (age < 30) {
              return 32;
            } else if (age >= 30 && age < 40) {
              return 33;
            } else if (age >= 40 && age < 60) {
              return 36;
            } else if (age >= 60) {
              return 38;
            }
            break;
        }
        break;
      // Lower
      case "body_fat_target2":
        switch (gender) {
          case "male":
            if (age < 30) {
              return 18;
            } else if (age >= 30 && age < 40) {
              return 20;
            } else if (age >= 40 && age < 60) {
              return 22;
            } else if (age >= 60) {
              return 24;
            }
            break;
          case "female":
            if (age < 30) {
              return 22;
            } else if (age >= 30 && age < 40) {
              return 27;
            } else if (age >= 40 && age < 60) {
              return 30;
            } else if (age >= 60) {
              return 38;
            }
            break;
        }
        break;
    }
  }

  fetchTarget(field): number {
    const age = moment().diff(this.dob, "years");
    const gender = this.gender;
    if (!age || !gender) return null;

    if (this.isWorldwide()) {
      return this.fetchAtHomeTarget(field, age, gender);
    } else {
      return this.fetchInLocationTarget(field, age, gender);
    }
  }
}

export interface IIndexClient {
  id?: number;
  name?: string;
  user_id?: number;
  location_id?: number;
  mindbody_id?: number;
  appointment_type?: string;
  found?: boolean;
  email?: string;
  time?: string;
  unique_mb?: number;
  phone?: string;
  referred_by?: string;
  status?: string;
  submitted?: boolean;
  consult_status?: boolean;
  consultation_id?: number;
  consultation?: IConsultation;
}

export class IndexClient implements IIndexClient {
  public id?: number;
  public name?: string;
  public user_id?: number;
  public location_id?: number;
  public mindbody_id?: number;
  public phone?: string;
  public appointment_type?: string;
  public email?: string;
  public found?: boolean;
  public time?: string;
  public referred_by?: string;
  public status?: string;
  public submitted?: boolean;
  public unique_mb?: number;
  public consult_status?: boolean;
  public consultation_id?: number;
  public consultation?: IConsultation;

  constructor( data: Partial<IIndexClient>,
               private locationService ) {
    Object.assign(this, data);
  }

  getLocationName() : string {
    if (this.locationService.locations) {
      return this.locationService.locations.filter((loc) => loc.id == this.location_id)[0].name
    }
  }
}
