import {ClientService} from '../../clients.service';
import {IonFab, ModalController, PopoverController} from '@ionic/angular';
import {Component, OnDestroy} from '@angular/core';
import {S3_Service} from '../../../../providers/S3-service.service';
import {GlobalsService} from '../../../../globals.service';
import {ClientDocService} from '../providers/client-doc/client-doc.service';
import {IClientDoc} from '../../../../models/client_doc';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import {TitleService} from '../../../../providers/title.service';
import {ShowDocumentModal} from './show-document.modal';
import {ScrollDetail} from '@ionic/core';
import {listAnimation, listStagger} from '../../../../animations/slideInListAnimation';
import {AwakenModal} from '../../../../shared/awaken-modal/awaken-modal.component';
import {ExternalDocumentsService} from '../../../../components/e-signature/e-signature.service';
import {ISignedDocument, SignedDocument} from '../../../../models/signed_document';
import {IMergedDocument} from '../../../../models/merged_documents';
import {ESignatureContainerComponent} from '../../../../components/e-signature/e-signature-container/e-signature-container.component';
import {
  SendForSignatureContainerComponent
} from '../../../../components/send-for-signature/send-for-signature-container/send-for-signature-container.component';
import {
  SignedDocumentSelectorContainerComponent
} from '../../../../components/signed-document-selector/signed-document-selector-container/signed-document-selector-container.component';
import {AwakenPopoverComponent} from '../../../../components/awaken-popover/awaken-popover.component';
import {NewDocumentModal} from '../../../../components/image-upload/new-document.modal';

@Component({
  selector: 'page-documents',
  templateUrl: 'documents.html',
  styleUrls: ['documents.scss'],
  animations: [
    listAnimation,
    listStagger
  ]
})
export class DocumentsPage implements OnDestroy {
  documents: IClientDoc[];
  file: string;
  clientObs: Subscription;
  signedDocuments: ISignedDocument[];
  clientId: number;
  isLoading = true;
  fetchDocumentsSubscription: Subscription;

  constructor( public clientService: ClientService,
               private s3: S3_Service,
               private modalCtrl: ModalController,
               private externalDocumentService: ExternalDocumentsService,
               private popoverCtrl: PopoverController,
               private global: GlobalsService,
               public clientDocService: ClientDocService,
               private titleService: TitleService ) {
    console.log(this)
    this.titleService.transparentHeader = true
  }

  ngOnDestroy(): void {
    if (this.clientObs) this.clientObs.unsubscribe()
    if (this.fetchDocumentsSubscription) this.fetchDocumentsSubscription.unsubscribe()
  }

  ionViewWillEnter() : void {
    this.titleService.setTitle('Documents')
    this.clientId = this.clientService.getClientID()
    this.loadClientDocuments(null, true);
  }

  ionViewWillUnload() : void {
    if ( this.clientObs ) { this.clientObs.unsubscribe(); }
    if (this.fetchDocumentsSubscription) this.fetchDocumentsSubscription.unsubscribe()
  }

  ionViewDidLeave() : void {
    if ( this.clientObs ) { this.clientObs.unsubscribe(); }
    if (this.fetchDocumentsSubscription) this.fetchDocumentsSubscription.unsubscribe()
  }


  /**
   * Watches the scrollTop to transition the header transparency.
   */
  onScroll($event : CustomEvent<ScrollDetail>) : void {
    if ($event && $event.detail && $event.detail.scrollTop) {
      const scrollTop = $event.detail.scrollTop;
      if ( scrollTop <= 50 ) {
        this.titleService.isScrolled.next(false)
      } else {
        this.titleService.isScrolled.next(true)
      }
    }
  }


  /**
   * Loads client_docs from the database
   */
  loadClientDocuments(event = null, refresh: boolean = false) {
    this.fetchDocumentsSubscription =
      this.clientDocService.fetchClientAndSignedDocuments(this.clientId, refresh).subscribe(
        ([clientDocs, signedDocs]) => {
      this.clientDocService.setMergedDocuments(signedDocs, clientDocs)
      this.isLoading = false
    })

    if (event) event.target.complete();
  }


  /**
   * Opens modal that allows you to upload a new document image.
   */
  async newDocumentModal() {
    const docModal = await this.modalCtrl.create({
      component: NewDocumentModal,
      componentProps: {
        clientId: this.clientId,
        key: `clients/documents/000/000/${this.clientId}/original`,
        documentType: 'Document',
        documentNames: _.map(this.clientDocService.getClientDocs(), (o) => o.name.toLowerCase())
      },
      cssClass: 'modal-fullscreen'
    })

    await docModal.present();

    docModal.onDidDismiss().then( data => {
      if (data && data.data && data.data.shouldRefresh) {
        this.clientDocService.create(data.data.image).subscribe(() => {
          this.loadClientDocuments(null, true)
        })
      }
    })
  }


  /**
   * Opens a modal of the supplied doc.
   * @param doc The document to be opened
   * @param fullscreen if true, modal is fullscreen
   * @param s3doc whether or not this is a doc from our DB or directly from S3
   */
  async openDocument(
    doc: IMergedDocument,
    fullscreen=false,
    s3doc: boolean = false) : Promise<any> {
    let key = null;

    key = s3doc ? doc.Key : doc.fileKey

    this.getImage(key).then( async awsSignedUrl => {
      const docModal = await this.modalCtrl.create({
        component: ShowDocumentModal,
        componentProps: {
          document_name: doc.name,
          document_url: awsSignedUrl,
          showFullscreen: !fullscreen,
          fileType: doc.fileType
        },
        cssClass: fullscreen ? 'modal-fullscreen' : ''
      })

      await docModal.present();

      docModal.onDidDismiss().then( data => {
        if ( data && data.data && data.data.showFullscreen ) {
          this.openDocument(doc, true)
        }
      })
    })
  }


  /**
   * First resets the current value for {@link ClientDocService.documents$} to an empty array.
   * Then it loads all documents for the current client and updates {@link ClientDocService.documents$}
   */
  loadAllDocuments( event = null ): void {
    this.clientDocService.setDocuments([]);
    const key = `clients/documents/000/000/${this.clientId}/original`;

    this.s3.getBucketContents(key).then( data => {
      const contentLength = data.Contents.length
      let foundLength = 0
      let missingLength = 0
      for ( const entry of data.Contents ) {
        entry.name = entry.Key.split('/').slice(_.indexOf(entry.Key.split('/'), 'original') + 1, entry.Key.split('/').length).join()
        this.clientDocService.addToAWSDocs(data)
        const dataParams = {
          url: `https://awaken180-assets.s3.us-east-2.amazonaws.com/${entry.Key}`,
          key: entry.Key,
          fileKey: entry.Key,
          name: entry.name,
          client_id: this.clientId,
          updated_at: entry.LastModified
        }
        this.clientDocService.addToDocuments(dataParams)
        this.clientDocService.createClientDocIfMissing(dataParams).subscribe(doc => {
          if (doc) {
            missingLength += 1
          } else {
            foundLength += 1
          }
        })

        if (missingLength + foundLength === contentLength) {
          this.global.handleResponse(`Found ${contentLength} documents in storage. ${foundLength} were already here and ${missingLength} were new`)
        }
      }
    })

    if (event) event.target.complete();
  }


  /**
   * Fetches signed URL from {@link s3} using name.
   * @param name AWS image name
   */
  getImage(name: string): Promise<string> {
    return this.s3.getSignedUrlFromAws(name)
  }


  /**
   * Deletes the supplied document from both S3 and the database.
   * @param document Document to be deleted
   */
  async deleteDocument( document : IMergedDocument ) {
    const key = document.fileKey;
    const that = this;
    const className = document.className

    const confirm = await this.modalCtrl.create({
      component: AwakenModal,
      componentProps: {
        title: `Are you sure you want to delete ${document.name}?`,
        subtitle: 'Deletions are irreversible.',
        type: 'confirm',
        urgent: true
      },
      cssClass: 'small-modal'
    })

    await confirm.present()
    const data = await confirm.onDidDismiss()

    if (data?.data === 'yes') {
      if (className === 'ClientDoc') {
        this.s3.deleteFromS3(key).then(() => {
          that.clientDocService.destroy(document.id).subscribe()
        })
          .catch(err => this.global.handleResponse(`Error: ${err}`))
      } else if (className === 'SignedDocument') {
        this.clientDocService.unlinkSignedDocument(document).subscribe(
          () => this.global.handleResponse('Successfully unlinked the document from this profile', false, 'success'),
          err => this.global.handleResponse(err.error, true)
        )
      }
    }
  }


  /**
   * Opens the popover that allows the user to do a variety of actions.
   * @param $event Mouse event to position the popover.
   * @param document Document to edit.
   */
  async showOptions($event: MouseEvent, document: IMergedDocument): Promise<any> {
    const options = [
      {name: 'View', dismissal: 'show', icon: {name: 'search'}, cssClass: 'text-success'},
    ]

    const hideOption = {name: 'Hide', dismissal: 'hide', icon: {name: 'close-circle-outline'}, cssClass: 'text-warning'}
    const shareOption = {name: 'Share', dismissal: 'share', icon: {name: 'share'}, cssClass: 'text-warning'}
    const deleteOptionLabel = this.determineSlidingOptionLabel(document)
    const deleteOption = {name: deleteOptionLabel, dismissal: 'delete', icon: {name: 'trash'}, cssClass: 'text-danger'}

    if (document.visible_to_client) options.push(hideOption)
    if (document.className !== 'SignedDocument' && !document.visible_to_client) options.push(shareOption)
    options.push(deleteOption)

    const popover = await this.popoverCtrl.create({
      event: $event,
      component: AwakenPopoverComponent,
      componentProps: {
        popoverSrc: options,
        translucent: true
      }
    })

    await popover.present()

    popover.onDidDismiss().then(dismissalTerm => {
      const value = dismissalTerm?.data?.dismissal
      switch(value) {
        case 'show':
          this.handleDocumentClick(document)
          break;
        case 'share':
          this.clientDocService.update(document.id, {visible_to_client: true}).subscribe(() => {
            this.loadClientDocuments()
          })
          break;
        case 'hide':
          this.clientDocService.update(document.id, {visible_to_client: false}).subscribe(() => {
            this.loadClientDocuments()
          })
          break;
        case 'delete':
          this.deleteDocument(document)
          break;
      }
    })
  }


  /**
   * Opens modal for a client to sign a document.
   * @param purpose: use case to determine which component to render
   * @param fab: HTML FAB element
   */
  async newESigModal(purpose: string, fab: IonFab) {
    let component;

    switch (purpose) {
      case 'in_person':
        component = ESignatureContainerComponent
        break;
      case 'send':
        component = SendForSignatureContainerComponent
        break;
      case 'search':
        component = SignedDocumentSelectorContainerComponent
        break;
    }

    const modal = await this.modalCtrl.create({
      component,
      componentProps: {
        client: this.clientService.getClient(),
        signedDocuments: this.signedDocuments,
      },
      cssClass: 'modal-fullscreen'
    })

    await modal.present()

    if (fab) await fab.close();
  }


  /**
   * Called by User click.  Depending on document type, calls a different function.
   */
  handleDocumentClick(document: IMergedDocument) {
    console.log(document, document.canBeViewed, document.className)
    if (document.canBeViewed) {
      if (document.className === 'ClientDoc') this.openDocument(document)
      if (document.className === 'SignedDocument') this.openSignedDocument(document)
    } else {
      this.global.handleResponse('This document cannot be opened unless signed', false, 'primary')
    }
  }


  async openSignedDocument(document: IClientDoc, fullscreen = false) {
    const b64toBlob = (base64, type = 'application/octet-stream') =>
    fetch(`data:${type};base64,${base64}`).then(res => res.blob())

    this.clientDocService.downloadEsignGenieDocument(document).subscribe( async response => {
      const type = 'application/pdf'
      const file = await b64toBlob(response.data, type)
      const base64file = atob(response.data)
      const fileURL = URL.createObjectURL(file);

      const docModal = await this.modalCtrl.create({
        component: ShowDocumentModal,
        componentProps: {
          document_name: document.name,
          document_url: fileURL,
          showFullscreen: !fullscreen,
          fileType: 'pdf',
          base64file,
        },
        cssClass: fullscreen ? 'modal-fullscreen' : ''
      })

      await docModal.present();

      docModal.onDidDismiss().then( data => {
        if ( data && data.data && data.data.showFullscreen ) {
          this.openSignedDocument(document, true)
        }
      })
    },
    err => {
      this.global.handleResponse(err.error, true)
    })
  }

  determineIcon(document: IMergedDocument): string {
    if (document.className === 'ClientDoc' && (document.fileKey && document.fileKey.indexOf('pdf') !== -1)) {
      return 'document'
    } else if (document.className === 'ClientDoc' && (!document.fileKey || document.fileKey.indexOf('pdf') === -1)) {
      return 'image'
    } else if (document.className === 'SignedDocument') {
      return 'finger-print'
    }
  }

  determineSlidingOptionLabel(document: any): string {
    return document.className === 'ClientDoc' ? 'Delete' : 'Unlink'
  }
}
