import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FoodOrder, IFoodOrder} from '../../../../../models/food-order';
import {HttpClient} from '@angular/common/http';
import {GlobalsService} from '../../../../../globals.service';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})

export class FoodOrderProvider {
  baseUrl: string;
  private foodOrders = new BehaviorSubject([] as IFoodOrder[]);
  foodOrders$: Observable<IFoodOrder[]> = this.foodOrders.asObservable();
  incompleteOrderLength: number;

  constructor( public http: HttpClient,
               private global: GlobalsService ) {
    this.baseUrl = this.global.base_url
  }


  /**
   * Sets the value for {@link foodOrders}
   * @param foodOrders: Array of food orders
   */
  setFoodOrders(foodOrders: IFoodOrder[]): void {
    this.foodOrders.next(foodOrders)
  }


  /**
   * Returns a safe copy of {@link foodOrders}.
   */
  getFoodOrders(): IFoodOrder[] {
    return Object.assign([], this.foodOrders.getValue())
  }


  /**
   * Reminds client to fill out their food order.
   * @param foodOrder: The food order that the client will be reminded about
   */
  resendOrderEmail(foodOrder) {
    const url = `${ this.baseUrl }/clients/${ foodOrder.client_id }/food_orders/${ foodOrder.id }/resend_email`
    return this.http.get(url)
  }


  /**
   * Returns the number of orders stored in {@link foodOrders}.
   */
  foodOrderLength() : number {
    return this.getFoodOrders().length
  }


  getFoodOrder(id: number): Observable<IFoodOrder> {
    const url = `${ this.baseUrl }/food_orders/${ id }`
    return this.http.get<IFoodOrder>(url)
  }


  /**
   * Handles both food_order database fetching for an individual client or an office.  Will return the office's orders if no
   * client_id is supplied.
   *
   * @param clientId: Requests food orders for a specific client
   * @param locationId: Requests food orders for a specific location
   * @param replace: if true, will replace the current value for {@link foodOrders} otherwise the result is appended to the list.
   */
  fetchFoodOrders(clientId = '', locationId = '', replace = true): Observable<{orders: IFoodOrder[], total_length: number}> {
    let url = `${ this.baseUrl }/food_orders`;
    const currOrders = replace ? [] : this.getFoodOrders()
    const listLength = currOrders.length;
    if ( clientId ) url = `${ this.baseUrl}/clients/${clientId}/food_orders`;
    url += `?location_id=${locationId}&list_length=${listLength}`;

    return this.http.get<{orders: IFoodOrder[], total_length: number}>(url).pipe(
      tap(foodOrders => {
        this.incompleteOrderLength = foodOrders.total_length;
        let ordered: any[]

        if (clientId) {
          foodOrders.orders = foodOrders.orders.map(foodOrder => {
            const calcTotalItems= foodOrder.nuzest_limit + foodOrder.limited_limit + foodOrder.general_limit
            return {...foodOrder, calcTotalItems}
          })
        }

        if ( replace ) {
          ordered = foodOrders.orders;
        } else {
          ordered = [...currOrders, ...foodOrders.orders];
        }

        ordered = _.orderBy(ordered, ['submitted_at', 'desc'])
        console.log(ordered)
        this.setFoodOrders(ordered)
      })
    )
  }


  /**
   * Creates a food order in the database and in turn, alerts the client of its availability.
   * The new order is added to {@link foodOrders}
   * @param foodOrder: Params to create the food order.
   */
  create(foodOrder : IFoodOrder) {
    const url = `${ this.baseUrl }/clients/${ foodOrder.client_id }/food_orders`
    return this.http.post<IFoodOrder>(url, foodOrder).pipe(
      tap(order => {
        const orders = this.getFoodOrders()
        orders.push(order)
        this.setFoodOrders(orders)
      })
    )
  }


  /**
   * Updates a food order in the database and modifies it within {@link foodOrders}.
   * @param foodOrder: Food order update params
   */
  update(foodOrder : IFoodOrder) {
    const orders = this.getFoodOrders()
    const url = `${ this.baseUrl }/food_orders/${ foodOrder.id }`

    return this.http.put<IFoodOrder>(url, foodOrder).pipe(
      tap(order => {
        const oldOrder = _.find(orders, {id: order.id})
        order.name = oldOrder.name
        order.location_name = oldOrder.location_name
        orders[_.indexOf(orders, oldOrder)] = order
        this.setFoodOrders(orders)
      })
    )
  }


  /**
   * Destroys the food order in the database and removes it from {@link foodOrders}.
   * @param foodOrderId: The food order ID to destroy
   */
  destroy(foodOrderId: number|string) {
    const url = `${ this.baseUrl }/food_orders/${ foodOrderId }`
    return this.http.delete(url).pipe(
      tap(() => {
        const orders = this.getFoodOrders()
        _.remove(orders, {id: foodOrderId})
        this.setFoodOrders(orders)
      })
    )
  }


  /**
   * Creates and submits scale-only food order for the provided client.
   * @param clientId: Client ID for whom we are sending the scale.
   */
  submitScaleOnlyFoodOrder(clientId: number): Observable<FoodOrder> {
    const url = `${ this.baseUrl }/clients/${clientId}/food_orders/submit`
    return this.http.post<FoodOrder>(url, {clientId, include_scale: true, general_limit: 0, limited_limit: 0, nuzest_limit: 0})
  }

}
